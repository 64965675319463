import React from "react"
import { Link } from "gatsby"
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import "../styles/components/newsCarousel.scss"

export default ({ slides }) => {
    const [pause, setPause] = React.useState(false)
    const timer = React.useRef()
    const [sliderRef, slider] = useKeenSlider({
        loop: true,
        duration: 2000,
        dragStart: () => {
            setPause(true)
        },
        dragEnd: () => {
            setPause(false)
        }
    })

    React.useEffect(() => {
        timer.current = setInterval(() => {
            if (!pause && slider) {
                slider.next()
            }
        }, 100000000000000000000)
        return () => {
            clearInterval(timer.current)
        }
    }, [pause, slider])

    return (
        <>
            <div ref={sliderRef} className="keen-slider">
                {slides.edges.map(({ node }, index) => (
                    <div key={index} className={`keen-slider__slide number-slide${index}`} style={{ backgroundImage: `url("${node.frontmatter.featuredImage}")` }}>
                        <div className="content-container">
                            <h2 className="title">{node.frontmatter.title}</h2>
                            <span className="desc-container">
                                <p className="description">{node.frontmatter.description.substr(0, 50)}...</p>
                                <Link className="read-more" to={`news${node.fields.slug}`}>Continue Reading...</Link>
                            </span>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}
