import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import "../styles/index.scss"

import Layout from "../components/layout/layout"
import NewsCarousel from "../components/newsCarousel"
import BigLinkButton from "../components/bigLinkButton"
import SEO from "../components/seo"

export default ({ location }) => {
  const { recentNews, teams } = useStaticQuery(graphql`
    {
      recentNews: allMarkdownRemark(limit: 3, sort: {fields: frontmatter___date, order: DESC}) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              featuredImage
              date
              description
            }
          }
        }
      }
      teams: allTeamsJson {
        edges {
          node {
            title
            logo
            fields {
              slug
            }
          }
        }
      }
    }
  `)

  return (
    <Layout location={location} cssPageName="index">
      <SEO title="Home" />

      <section className="news">
        <NewsCarousel slides={recentNews} />

        <BigLinkButton
          text="All News"
          link="/news"
          arrow="right"
          backgroundColor="blue"
        />
      </section>

      <section className="teams">
        <h2 className="teams-header">Teams</h2>
        <div className="teams-list">
          {teams.edges.sort((a, b) => a.node.title < b.node.title ? -1 : 1).map(({ node }) => (
            <Link key={node.title} to={`/teams${node.fields.slug}`}><img className="team-logo" src={node.logo} alt={node.title} /></Link>
          ))}
        </div>
        {/* <BigLinkButton
          text="Get Tickets"
          link="/tickets"
          arrow="right"
          backgroundColor="red"
        /> */}
      </section>

      <section className="what-is">
        <h2 className="what-is-header">What is the United States Winter League?</h2>
        <p className="what-is-p">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Iste voluptates aperiam quisquam quibusdam in at molestias praesentium nesciunt commodi sit impedit ipsum nihil, non consequuntur. Architecto eos saepe nemo dolor?</p>
      </section>
    </Layout>
  )
}