import React from 'react'
import { Link } from "gatsby"
import '../styles/components/bigLinkButton.scss'

import Arrow from "./svg/arrow"

export default ({
    text,
    link,
    arrow,
    lift,
    underline,
    backgroundColor
}) => {
    return (
        <div className="big-link-button-container">
            <Link to={link} className={`big-link-button${backgroundColor ? ` ${backgroundColor}` : ""}`}>
                {text} {arrow && <Arrow orientation={arrow} />}
            </Link>
        </div>
    )
}